import React, { memo } from 'react';
// mui components
import {
  TextField,
  filledInputClasses,
  FilledTextFieldProps,
  OutlinedInputProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export interface BaseInputProps extends FilledTextFieldProps {
  dark?: boolean;
  readOnly?: boolean;
}

const StyledTextField = styled(({ InputProps, dark = false, ...props }: BaseInputProps) => (
  <TextField
    InputProps={{ ...InputProps, disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(({ theme, dark, readOnly }) => ({
  pointerEvents: readOnly ? 'none' : 'auto',
  [`& .${filledInputClasses.input}`]: {
    ...theme.fontStyles.placeholder,
    fontWeight: 'normal',
    color: dark ? theme.colors.text.dark.body : theme.colors.text.light.body,
    '&:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active':
      {
        transition: 'all 2147483647s ease-in-out 2147483647s',
        transitionProperty: 'background-color, color',
      },
  },
  [`& .${filledInputClasses.root}`]: {
    border: `1px solid ${
      dark ? theme.colors.background.dark.b1 : theme.colors.background.light.b4
    }`,
    borderRadius: '4px',
    backgroundColor: dark ? theme.colors.background.dark.b1 : theme.colors.main.white,
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:before': {
      border: 'unset !important',
    },
    '&:after': {
      border: 'unset !important',
    },
    '&:hover': {
      backgroundColor: dark ? theme.colors.background.dark.b1 : 'transparent',
      borderColor: theme.colors.main.purple,
    },
    '&.Mui-focused': {
      backgroundColor: dark ? theme.colors.background.dark.b1 : 'transparent',
      borderColor: theme.colors.main.purple,
    },
    '&.Mui-error': {
      border: '1px solid #E22716',
    },
    '& .MuiInputAdornment-root': {
      marginRight: '8px',
    },
  },
  '& .MuiInputLabel-shrink': {
    color: `${
      dark ? theme.colors.text.dark.placeholder : theme.colors.text.light.label
    } !important`,
  },
  '& label': {
    color: dark ? theme.colors.text.dark.placeholder : theme.colors.text.light.label,
  },
  '& .Mui-error': {
    color: dark
      ? `${theme.colors.text.dark.label} !important`
      : `${theme.colors.text.light.label} !important`,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: theme.colors.text.dark.body,
  },
}));

const BaseInput: React.FC<BaseInputProps> = (props) => {
  return <StyledTextField {...props} />;
};

export default memo(BaseInput);
