// mui components
import { LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material/styles';
// components
import PurpleButton from 'components/purple-button';

export const StyledSubmitButton = styled((props: LoadingButtonProps) => (
  <PurpleButton type="submit" {...props} />
))(() => ({
  width: '87px',
  height: '40px',
  marginTop: '10px',
}));
