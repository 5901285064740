import React, { FC, memo } from 'react';
// mui-components
import { Grid, Typography } from '@mui/material';
// components
import DialogModal from 'components/dialog-modal';
// styles
import { colors } from 'styles/colors';

interface IProps {
  open: boolean;
  handleClose: () => void;
  message: string;
  title?: string;
}

const ErrorModal: FC<IProps> = ({ message, title = 'Error', ...props }) => {
  return (
    <DialogModal withActions {...props}>
      <Grid container justifyContent="center">
        <Typography
          sx={{
            marginBottom: '8px',
            fontSize: '32px',
            lineHeight: '40px',
            color: colors.text.light.title,
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <Typography
          sx={{
            fontSize: '18px',
            lineHeight: '26px',
            fontWeight: 'lighter',
            color: colors.text.light.label,
            textAlign: 'center',
          }}
        >
          {message}
        </Typography>
      </Grid>
    </DialogModal>
  );
};

export default memo(ErrorModal);
