import React, { useCallback, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// mui components
import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import {
  StyledLabel,
  StyledValue,
  StyledEditButton,
  StyledSubmitButton,
} from '../styled-components';
import DialogModal from 'components/dialog-modal';
import Text from 'components/text';
import ErrorModal from 'components/error-modal';
// hooks
import { useError } from 'hooks/useError';
// api
import api from 'utils/api';
// controllers
import PasswordInputController from 'controllers/password-input-controller';
// validations
import { ChangePasswordSchema } from './validation';

const StyledInputContainer = styled((props: GridProps) => (
  <Grid container flexDirection="column" {...props} />
))(() => ({
  marginBottom: '26px',
}));

const ChangedPasswordInput: React.FC = () => {
  const { message, setError } = useError();

  const [isSubmitting, setSubmitting] = useState(false);

  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [openErrorModal, setOpenErrorModal] = useState(false);

  const form = useForm({ resolver: yupResolver(ChangePasswordSchema) });

  const [showInput, setShowInput] = useState(false);

  const handleToggleInput = useCallback(() => {
    setShowInput((prevState) => !prevState);
  }, []);

  const onSubmit = useCallback(
    async (formValues: any) => {
      try {
        setSubmitting(true);
        await api.auth.changePassword({ data: formValues });
        setShowInput(false);
        setOpenSuccessModal(true);
        form.reset();
      } catch (err) {
        setError(err);
        setOpenErrorModal(true);
      } finally {
        setSubmitting(false);
      }
    },
    [form, setError]
  );

  return (
    <>
      <DialogModal
        withActions
        open={openSuccessModal}
        handleClose={() => {
          setOpenSuccessModal(false);
        }}
      >
        <Text textAlign="center" fontSize="20px" marginY="20px">
          The password of your account has updated successfully!
        </Text>
      </DialogModal>
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Grid
            container
            flexDirection="column"
            sx={{ padding: '26px 0 24px', boxShadow: 'inset 0px -1px 0px #DFE1E6' }}
          >
            <Grid
              container
              alignItems="flex-end"
              justifyContent="space-between"
              sx={{ marginBottom: showInput ? '26px' : '12px' }}
            >
              <StyledLabel>Password</StyledLabel>
              <StyledEditButton disableRipple onClick={handleToggleInput}>
                {showInput ? 'Cancel' : 'Edit'}
              </StyledEditButton>
            </Grid>
            {showInput ? (
              <Grid container flexDirection="column">
                <StyledInputContainer>
                  <PasswordInputController withError name="oldPassword" label="Old password" />
                </StyledInputContainer>
                <StyledInputContainer>
                  <PasswordInputController withError name="newPassword" label="New password" />
                </StyledInputContainer>
                <PasswordInputController
                  withError
                  name="passwordConfirm"
                  label="Confirm new password"
                />
                <StyledSubmitButton loading={isSubmitting}>Save</StyledSubmitButton>
              </Grid>
            ) : (
              <StyledValue>•••••••••••</StyledValue>
            )}
          </Grid>
        </form>
      </FormProvider>
      <ErrorModal
        open={openErrorModal}
        handleClose={() => setOpenErrorModal(false)}
        message={message}
      />
    </>
  );
};

export default ChangedPasswordInput;
