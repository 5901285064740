// mui components
import { styled } from '@mui/material/styles';
// components
import PurpleButton from 'components/purple-button';

const StyledButton = styled(PurpleButton)(() => ({
  margin: '16px 0 24px',
}));

export default StyledButton;
