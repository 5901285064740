import { createTheme } from '@mui/material';
// colors
import { colors, DeclaredColors } from './colors';
// font styles
import { fontStyles, DeclaredFontStyles } from './font-styles';

declare module '@mui/material/styles' {
  interface Theme {
    colors: DeclaredColors;
    fontStyles: DeclaredFontStyles;
  }

  interface ThemeOptions {
    colors?: DeclaredColors;
    fontStyles?: DeclaredFontStyles;
  }
}

export const theme = createTheme({
  colors,
  fontStyles,
});
