// mui components
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  ...theme.fontStyles.subtext,
  color: theme.colors.text.light.label,
  '& svg': {
    width: '18px',
    height: '18px',
    marginLeft: '6px',
    '& path': {
      fill: theme.colors.system.success,
    },
  },
}));
