// mui components
import { styled } from '@mui/material/styles';
// components
import { StyledValue } from './styled-value';

export const StyledAmount = styled(StyledValue)({
  display: 'inline-block',
  '&:first-letter': {
    marginRight: '1px',
    fontWeight: 'bold',
  },
});
