import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledText = styled(Typography)(({ theme }) => ({
  marginRight: '6px',
  ...theme.fontStyles.subtext,
  color: theme.colors.text.dark.body,
}));

export default StyledText;
