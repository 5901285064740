import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// components
import BaseInput from 'components/inputs/base-input';

interface OwnProps {
  name: string;
  label: string;
  withError?: boolean;
  dark?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  [x: string]: any;
}

const BaseInputController: React.FC<OwnProps> = ({
  name = '',
  defaultValue = '',
  withError,
  onChange: topOnChange,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field: { value, onChange, onBlur }, fieldState: { error, isDirty } }) => (
        <BaseInput
          {...props}
          value={isDirty ? value : defaultValue}
          variant="filled"
          error={!!error}
          onBlur={onBlur}
          onChange={(e) => {
            if (topOnChange) topOnChange(e);
            onChange(e);
          }}
          helperText={withError ? error?.message || ' ' : undefined}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

export default memo(BaseInputController);
