import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledInputContainer = styled((props: GridProps) => (
  <Grid container flexDirection="column" {...props} />
))(() => ({
  marginBottom: '4px',
}));

export default StyledInputContainer;
