import React from 'react';
// mui components
import { Tooltip as MuiTooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const Tooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <MuiTooltip {...props} arrow placement="top" enterTouchDelay={0} classes={{ popper: className }}>
    {children}
  </MuiTooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.main.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    width: '193px',
    padding: '8px 10px',
    ...theme.fontStyles.subtext,
    color: theme.colors.main.white,
    textAlign: 'center',
    backgroundColor: theme.colors.main.black,
  },
}));

export default Tooltip;
