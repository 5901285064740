import { Breakpoint, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';

type BreakpointOrNull = Breakpoint | null;

export const useWidth = () => {
  const theme: Theme = useTheme();

  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();

  const width =
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs';

  const isMobile = useMemo(() => width === 'xs', [width]);
  const isTablet = useMemo(() => width === 'sm', [width]);
  const isMobileOrTablet = useMemo(() => ['sm', 'xs'].includes(width), [width]);

  return {
    width,
    isMobile,
    isTablet,
    isMobileOrTablet,
  };
};
