import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.fontStyles.subtext,
  color: theme.colors.main.yellow,
  textDecoration: 'underline',
  cursor: 'pointer',
  zIndex: 1,
}));

export default StyledLink;
