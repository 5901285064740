import { useMemo, useState } from 'react';

export interface IError {
  data: { message: string };
}

export const useError = () => {
  const [error, setError] = useState<IError | null | any>(null);

  const message = useMemo(() => {
    if (!error) return '';
    if (typeof error?.data?.message === 'string') return error?.data?.message;
    return error.length ? error : 'Something went wrong';
  }, [error]);

  return {
    message,
    setError,
  };
};
