import { CSSProperties } from 'react';
// colors
import { colors } from './colors';

export interface IFontStyles {
  fontSize: CSSProperties['fontSize'];
  lineHeight: CSSProperties['lineHeight'];
  fontWeight: CSSProperties['fontWeight'];
  color: CSSProperties['color'];
  fontFamily?: CSSProperties['fontFamily'];
}

export type DeclaredFontStyles = {
  h1: IFontStyles;
  h2: IFontStyles;
  h3: IFontStyles;
  h4: IFontStyles;
  body: IFontStyles;
  subtext: IFontStyles;
  tiny: IFontStyles;
  labelSmall: IFontStyles;
  placeholder: IFontStyles;
  bodyLight: IFontStyles;
  small: IFontStyles;
};

export const fontStyles: DeclaredFontStyles = {
  h1: {
    fontSize: '48px',
    lineHeight: '72px',
    fontWeight: 'bold',
    color: colors.text.light.title,
    fontFamily: '"Founders Grotesk", sans serif',
  },
  h2: {
    fontSize: '36px',
    lineHeight: '54px',
    fontWeight: 'bold',
    color: colors.text.light.body,
    fontFamily: '"Founders Grotesk", sans serif',
  },
  h3: {
    fontSize: '32px',
    lineHeight: '40px',
    fontWeight: 'bold',
    color: colors.text.light.body,
    fontFamily: '"Founders Grotesk", sans serif',
  },
  h4: {
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: 'bold',
    color: colors.text.light.body,
    fontFamily: '"Founders Grotesk", sans serif',
  },
  body: {
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 'normal',
    color: colors.text.light.body,
    fontFamily: '"Founders Grotesk", sans serif',
  },
  subtext: {
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 'normal',
    color: colors.text.light.body,
    fontFamily: '"Founders Grotesk", sans serif',
  },
  tiny: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 'lighter',
    color: colors.text.light.body,
    fontFamily: '"Founders Grotesk", sans serif',
  },
  labelSmall: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 'lighter',
    color: colors.text.light.body,
    fontFamily: '""Founders Grotesk"", sans serif',
  },
  placeholder: {
    fontSize: '18px',
    lineHeight: '20px',
    fontWeight: 'lighter',
    color: colors.text.light.body,
    fontFamily: '"Founders Grotesk", sans serif',
  },
  bodyLight: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'lighter',
    color: colors.text.light.body,
    fontFamily: '"Founders Grotesk", sans serif',
  },
  small: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 'lighter',
    color: colors.text.light.body,
    fontFamily: '"Founders Grotesk", sans serif',
  },
};
