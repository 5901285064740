import React, { memo } from 'react';
// mui components
import { Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';
// icons
import { ReactComponent as CheckboxEmpty } from 'assets/icons/checkbox_empty.svg';
import { ReactComponent as CheckboxFull } from 'assets/icons/checkbox_full.svg';

interface OwnProps extends CheckboxProps {}

const Checkbox: React.FC<OwnProps> = (props) => {
  return (
    <MuiCheckbox
      icon={<CheckboxEmpty />}
      checkedIcon={<CheckboxFull />}
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      {...props}
    />
  );
};

export default memo(Checkbox);
