// mui components
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledEditButton = styled(Button)(({ theme }) => ({
  padding: '2px',
  ...theme.fontStyles.subtext,
  textDecoration: 'underline',
  textTransform: 'unset',
}));
