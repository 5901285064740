import { useRequest } from '../useRequest';
// api
import api from 'utils/api';
// types
import { IDeal } from '../useUserData';
import { IData } from '../useDealState';
import { SWRConfiguration } from 'swr';

export const useGetDealList = () => useRequest<IDeal[]>('/deals', () => api.deals.getDealList());

export const useGetDeal = (id: string, options?: SWRConfiguration) =>
  useRequest<IDeal>(`/deal/${id}`, () => api.deals.getDeal({ id }), options);

export const useGetTracks = (id: string, artistSpotifyId?: string, options?: SWRConfiguration) =>
  useRequest<IData[]>(
    artistSpotifyId ? `/deal/${id}/tracks` : null,
    () => api.spotify.getTopTracks({ id: artistSpotifyId }),
    options
  );

export const useGetAllTracks = (id: string, artistSpotifyId?: string, options?: SWRConfiguration) =>
  useRequest<IData[]>(
    artistSpotifyId ? `/deal/${id}/all-tracks` : null,
    () => api.spotify.getAllTracks({ id: artistSpotifyId }),
    options
  );
