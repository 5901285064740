import React, { useCallback, useState } from 'react';
// mui components
import { InputAdornment, InputAdornmentProps } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import BaseInput from './base-input';
// types
import { BaseInputProps } from './base-input';

interface IAdornment extends InputAdornmentProps {
  dark?: boolean;
}

const StyledEndAdornment = styled(({ dark, ...props }: IAdornment) => (
  <InputAdornment {...props} />
))(({ theme, dark = false }) => ({
  cursor: 'pointer',
  '& p': {
    color: dark ? theme.colors.text.dark.label : theme.colors.text.light.label,
  },
}));

const PasswordInput: React.FC<BaseInputProps> = ({ dark = false, ...props }) => {
  const [showPass, setShowPass] = useState(false);

  const handleChangeShow = useCallback(() => {
    setShowPass((prevState) => !prevState);
  }, []);

  return (
    <BaseInput
      {...props}
      dark={dark}
      type={showPass ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <StyledEndAdornment dark={dark} position="end" onClick={handleChangeShow}>
            {showPass ? 'Hide' : 'Show'}
          </StyledEndAdornment>
        ),
      }}
    />
  );
};

export default PasswordInput;
