import { useReducer, useEffect } from 'react';

export interface ISteps {
  id: number;
  name: string;
  icon: string;
  active: boolean;
  completed: boolean;
}

interface IState {
  deal: any;
  tracks: any;
  form: object;
  steps: ISteps[];
  isFullCatalogue: boolean;
  currentStepNumber: number;
  allTracksSelected: boolean;
}

interface IAction {
  payload: any;
  type: ActionType;
}

const steps: ISteps[] = [
  {
    id: 1,
    name: 'Review info',
    icon: 'ChartIcon',
    active: false,
    completed: false,
  },

  {
    id: 2,
    name: 'Select songs',
    icon: 'SongIcon',
    active: false,
    completed: false,
  },
  {
    id: 3,
    name: 'Calculate deal',
    icon: 'DealIcon',
    active: false,
    completed: false,
  },
  {
    id: 4,
    name: 'Upload report',
    icon: 'UploadIcon',
    active: false,
    completed: false,
  },
];

const initialState: IState = {
  steps,
  deal: null,
  tracks: null,
  currentStepNumber: 0,
  isFullCatalogue: false,
  allTracksSelected: false,
  form: {
    ownEstimation: 0,
    commitmentLength: 1,
  },
};

enum ActionType {
  SET_DEAL = 'SET_DEAL',
  SET_TRACKS = 'SET_TRACKS',
  ADD_TRACK = 'ADD_TRACK',
  TOGGLE_TRACK = 'TOGGLE_TRACK',
  SET_FORM_DATA = 'SET_FORM_DATA',
  SELECT_ALL_TRACK = 'SELECT_ALL_TRACK',
  SET_TRACK_OWNERSHIP = 'SET_TRACK_OWNERSHIP',
  TOGGLE_FULL_CATALOGUE = 'TOGGLE_FULL_CATALOGUE',
  MARK_STEP_AS_COMPLETED = 'MARK_STEP_AS_COMPLETED',
  SET_CURRENT_STEP_NUMBER = 'SET_CURRENT_STEP_NUMBER',
  SET_REPORT_STATUS = 'SET_REPORT_STATUS',
  REMOVE_REPORT = 'REMOVE_REPORT',
  ADD_REPORT = 'ADD_REPORT',
  REPLACE_TEMP_REPORT = 'REPLACE_TEMP_REPORT',
}

const reducer = (state: IState, action: IAction) => {
  switch (action.type) {
    case ActionType.SET_CURRENT_STEP_NUMBER:
      return {
        ...state,
        steps: [
          ...state.steps.map((step) => ({
            ...step,
            active: action.payload === step.id,
          })),
        ],
        currentStepNumber: action.payload,
      };
    case ActionType.MARK_STEP_AS_COMPLETED:
      return {
        ...state,
        steps: [
          ...state.steps.map((step) => ({
            ...step,
            completed: action.payload === step.id,
          })),
        ],
      };
    case ActionType.SET_DEAL:
      return {
        ...state,
        deal: action.payload,
      };
    case ActionType.SET_TRACKS:
      return {
        ...state,
        //@ts-ignore
        tracks: (action.payload || []).map((track: any) => ({
          ...track,
          selected: false,
        })),
      };
    case ActionType.SET_FORM_DATA:
      return {
        ...state,
        form: {
          ...state.form,
          // @ts-ignore
          ...action.payload,
        },
      };
    case ActionType.TOGGLE_TRACK: {
      const tracks = [
        ...state.tracks.map((track: any) => {
          return track.id === action.payload
            ? {
                ...track,
                selected: !track.selected,
              }
            : track;
        }),
      ];
      return {
        ...state,
        tracks,
        allTracksSelected: tracks.every((track: any) => track.selected),
      };
    }
    case ActionType.TOGGLE_FULL_CATALOGUE:
      return {
        ...state,
        tracks: [
          ...state.tracks.map((track: any) => ({
            ...track,
            selected: action.payload,
          })),
        ],
        isFullCatalogue: action.payload,
        allTracksSelected: false,
      };
    case ActionType.SET_TRACK_OWNERSHIP:
      return {
        ...state,
        tracks: [
          ...state.tracks.map((track: any) => {
            return track.id === action.payload.track_id
              ? {
                  ...track,
                  ownership: action.payload.ownership,
                }
              : track;
          }),
        ],
      };
    case ActionType.SELECT_ALL_TRACK:
      return {
        ...state,
        tracks: [
          ...state.tracks.map((track: any) => ({
            ...track,
            selected: !state.allTracksSelected,
          })),
        ],
        allTracksSelected: !state.allTracksSelected,
      };
    case ActionType.ADD_TRACK:
      return {
        ...state,
        tracks: [...state.tracks, action.payload],
      };
    case ActionType.SET_REPORT_STATUS:
      return {
        ...state,
        deal: {
          ...state.deal,
          reports: [
            ...state.deal.reports.map((report: any) => {
              return report.id === action.payload.reportId
                ? {
                    ...report,
                    status: action.payload.status,
                  }
                : report;
            }),
          ],
        },
      };
    case ActionType.REMOVE_REPORT:
      return {
        ...state,
        deal: {
          ...state.deal,
          reports: [...state.deal.reports.filter((report: any) => report.id !== action.payload)],
        },
      };
    case ActionType.ADD_REPORT:
      return {
        ...state,
        deal: {
          ...state.deal,
          reports: [...state.deal.reports, action.payload],
        },
      };
    case ActionType.REPLACE_TEMP_REPORT:
      return {
        ...state,
        deal: {
          ...state.deal,
          reports: [
            ...state.deal.reports.map((report: any) => {
              return report.id === action.payload.tempId ? action.payload.report : report;
            }),
          ],
        },
      };
    default:
      return state;
  }
};

export interface IDealStore {
  state: IState;
  dispatch: React.Dispatch<IAction>;
  setCurrentStepNumber: (stepNumber: number) => void;
  markStepAsCompleted: (stepNumber: number) => void;
  setDeal: (deal: any) => void;
  setTracks: (tracks: any) => void;
  setFormData: (data: any) => void;
  toggleFullCatalogue: (isFullCatalogue: boolean) => void;
  toggleTrack: (track_id: string) => void;
  setTrackOwnership: (track_id: string, ownership: number | number[]) => void;
  selectAllTracks: () => void;
  addTrack: (track: any) => void;
  setReportStatus: (reportId: number, status: string) => void;
  removeReport: (reportId: number) => void;
  addReport: (report: any) => void;
  replaceTempReport: (report: any) => void;
}

const DealStore = (): IDealStore => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (window?.location?.hostname === 'localhost') {
      console.dir(state);
    }
  }, [state]);

  const setCurrentStepNumber = (stepNumber: number) => {
    dispatch({
      type: ActionType.SET_CURRENT_STEP_NUMBER,
      payload: stepNumber,
    });
  };

  const markStepAsCompleted = (stepNumber: number) => {
    dispatch({
      type: ActionType.MARK_STEP_AS_COMPLETED,
      payload: stepNumber,
    });
  };

  const setDeal = (deal: any) => {
    dispatch({
      type: ActionType.SET_DEAL,
      payload: deal,
    });
  };

  const setTracks = (tracks: any) => {
    dispatch({
      type: ActionType.SET_TRACKS,
      payload: tracks,
    });
  };

  const setFormData = (data: any) => {
    dispatch({
      type: ActionType.SET_FORM_DATA,
      payload: {
        ...state.form,
        ...data,
      },
    });
  };

  const toggleTrack = (track_id: string) => {
    dispatch({
      type: ActionType.TOGGLE_TRACK,
      payload: track_id,
    });
  };

  const toggleFullCatalogue = (isFullCatalogue: boolean = !state.isFullCatalogue) => {
    dispatch({
      type: ActionType.TOGGLE_FULL_CATALOGUE,
      payload: isFullCatalogue,
    });
  };

  const setTrackOwnership = (track_id: string, ownership: number | number[]) => {
    dispatch({
      type: ActionType.SET_TRACK_OWNERSHIP,
      payload: { ownership, track_id },
    });
  };

  const selectAllTracks = () => {
    dispatch({
      type: ActionType.SELECT_ALL_TRACK,
      payload: null,
    });
  };

  const addTrack = (track: any) => {
    dispatch({
      type: ActionType.ADD_TRACK,
      payload: track,
    });
  };

  const setReportStatus = (reportId: number, status: string) => {
    dispatch({
      type: ActionType.SET_REPORT_STATUS,
      payload: { reportId, status },
    });
  };

  const removeReport = (reportId: number) => {
    dispatch({
      type: ActionType.REMOVE_REPORT,
      payload: reportId,
    });
  };

  const addReport = (report: any) => {
    dispatch({
      type: ActionType.ADD_REPORT,
      payload: report,
    });
  };

  const replaceTempReport = (report: any) => {
    dispatch({
      type: ActionType.REPLACE_TEMP_REPORT,
      payload: report,
    });
  };

  return {
    state,
    setDeal,
    dispatch,
    addTrack,
    addReport,
    setTracks,
    setFormData,
    toggleTrack,
    removeReport,
    setReportStatus,
    selectAllTracks,
    setTrackOwnership,
    replaceTempReport,
    toggleFullCatalogue,
    markStepAsCompleted,
    setCurrentStepNumber,
  };
};

export default DealStore;
