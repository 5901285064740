import React, { memo } from 'react';
// mui components
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
// images
import purpleFigure from './assets/purple_figure.png';
import man from './assets/man.png';
// styles
import { colors } from 'styles/colors';

const StyledPurpleFigure = styled('img')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '1320px',
  transform: 'rotate(-210deg) translateX(-10%) translateY(-30%)',
  zIndex: 0,
  pointerEvents: 'none',
  [theme.breakpoints.down('md')]: {
    transform: 'rotate(-210deg) translateX(-10%) translateY(-30%)',
    top: 0,
    bottom: 'unset',
    width: 500,
  },
}));

const StyledManImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: '774px',
  transform: 'translateY(-55%) translateX(60%)',
  zIndex: 0,
  pointerEvents: 'none',
  [theme.breakpoints.down('md')]: {
    top: 0,
    bottom: 'unset',
    width: 300,
    transform: 'translateY(0) translateX(60%)',
  },
}));

interface OwnProps {
  children: JSX.Element;
}

const AuthLayout: React.FC<OwnProps> = ({ children }) => {
  return (
    <Grid
      container
      sx={{
        minHeight: 'calc(100vh - 72px)',
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: colors.main.black,
        padding: { xs: '30px', sm: '0 30px' },
      }}
    >
      <StyledPurpleFigure src={purpleFigure} alt="purple figure" />
      <StyledManImage src={man} alt="man" />
      <Grid
        container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ zIndex: 1 }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default memo(AuthLayout);
